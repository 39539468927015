import { Col, Input, Modal, notification, Row, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Formik } from "formik";
import { useRef, useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import ButtonReuse from "../../../components/Buttons/Button";
import UploadAttachment from "./UploadAttachment";
import * as yup from "yup";
import { getToken } from "../../../auth";
import Switch from "react-switch";
import { allowOnlyNumbers } from "../../../utils/util";
import { editRevenueRecord } from "../../../utils/enums";
import "./RecordRevenue.scss";

const EditRecordRevenue = (props: {
  newCurrentEarn?: any;
  newCurrentNotes?: any;
  monthId?: any;
  disabledInput?: any;
  currentEarningId?: any;
  deleteData?: any;
  handleCancel?: any;
  earnings?: any;
  setEdit?: any;
  setDeleteEarnings?: any;
  setOpenRecordRevenue?: any;
  pEarn?: boolean;
  currencyId?: any;
  currency_rate?: any;
  getEarnings?:any;
  directExpense?: number;
}) => {
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isModalVisibleDelete, setisModalVisibleDelete] = useState(false);
  const [Pearn, setPearn] = useState(props.pEarn ? true : false);
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState();

  const EarnedAmt = useRef(0);
  const handleSwitchChange = () => {
    setPearn(!Pearn);
  };

  var token = getToken();

  useEffect(() => {
    handleMonthSelected(props.monthId);
  }, []);

  const handleMonthSelected = (month_id) => {
    AxiosConfig.get("rates/" + month_id, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((data) => {
        const temp: any = data.data?.monthRates?.mastercurrencies.filter(
          (currency: any) =>
            currency.currencyrates.currency_id === props.currencyId
        );
        setSelectedCurrencyRate(
          temp[0]?.currencyrates.rate ?? props.currency_rate
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = yup.object({
    earned_percentage: yup.number(),
    notes: yup.string().trim().required("Please add Notes"),
    projected_expenses: yup.number(),
  });

  const showEditModal = () => {
    setIsModalVisibleEdit(true);
  };
  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const showModalDelete = () => {
    setisModalVisibleDelete(true);
  };
  const handleCancelDeleteModal = () => {
    setisModalVisibleDelete(false);
  };
  const filtered = props.earnings.filter(
    (month) => props.monthId === month.financialmonth_id
  );

  const [newitems, setNewItems] = useState({
    earned_percentage: filtered[0].earned_percentage,
    notes: filtered[0].notes,
    p_earn: Pearn,
    projected_expenses: parseFloat(filtered[0].projected_expenses),
  });

  const handleChangeEvent = (event: any) => {
    event.preventDefault();
    if (event.target.name === "earned_percentage")
      EarnedAmt.current = event.target.value;
    setNewItems({
      ...newitems,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeDirectExpenseEvent = (event) => {
    event.preventDefault();
    if (event.target.name === "projected_expenses")
      EarnedAmt.current = event.target.value;
    setNewItems({
      ...newitems,
      [event.target.name]: parseFloat(event.target.value),
    });
  };

  const handleEdit = () => {
    props.setEdit(false);

    if(!newitems?.earned_percentage && newitems?.earned_percentage?.length == 0){
      const notify = () => {
        notification.open({ 
          message:"",
          description: "Please enter Earned percentage",
          style: { color: "red" },
        });
      };
      {
        notify();
      }
    }else{ 
    if (newitems.notes.trim().length > 0) {
      AxiosConfig.put(
        "poearnings/" + filtered[0].id,
        {
          ...newitems,
          p_earn: Pearn,
          po_currency_conversion_rate: selectedCurrencyRate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((response) => {
          if (response.status == 200) {
            const notify = () => {
              notification.open({
                message: response.data.status.message,
              });
            };
            {
              notify();
            }
            props.setOpenRecordRevenue(true);
          }
         props.getEarnings()
         props.handleCancel()
        })
        .catch(function (error) {
          if (error.response.status === 404) {
            notification.open({
              message: "",
              description: "Projected Direct STUs/non-STUs expenses exceeds the total Projected Direct STUs/non-STUs expenses for the PO",
              style: { color: "red" },
            });
          } else {
            const notify = () => {
              notification.open({
                message: "",
                description: error.response.data.status.message,
                style: { color: "red" },
              });
            };
            notify();
          }
        });
    }
    }
  };

  const EditRecognitionForm = (
    <Formik
      initialValues={{
        earned_percentage: "",
        notes: "",
        projected_expenses: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleEdit}
    >
      {({ handleSubmit, errors, resetForm }) => (
        <form onSubmit={handleSubmit} className="add_po_earning_form">
          {props.disabledInput && (
            <>
              <Row>
                <Col span={12}>
                  <Tooltip title="Enter the %age of PO being recognized for the current financial month">
                    <label>{editRevenueRecord.labelForValuePer}</label>
                  </Tooltip>
                  <Input
                    placeholder="Enter the Value"
                    name="earned_percentage"
                    value={newitems.earned_percentage}
                    onChange={(event: any) => {
                      const val = allowOnlyNumbers(event);
                      if (val !== false) {
                        return handleChangeEvent(event);
                      }
                      return false;
                    }}
                    type="number"
                    className="input--field"
                  ></Input>
                  <p className="display_error">{errors.earned_percentage}</p>
                  <Switch
                    onChange={handleSwitchChange}
                    checked={Pearn}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    width={36}
                    height={20}
                    handleDiameter={16}
                    onColor="#EF0723"
                    offColor="#3FF528"
                  />
                  <Col className="earnswitch">
                    {Pearn ? <p>{"LOSS"}</p> : <p> {"GAIN"}</p>}
                  </Col>
                  <label>{editRevenueRecord.labelForAttachments}</label>
                  <Col span={12}>
                    <ButtonReuse
                      value="Edit"
                      type="submit"
                      className="edit-image-button"
                      onClick={showEditModal}
                    />
                    <Modal
                      title="Attachments"
                      className="AttachmentsModal"
                      visible={isModalVisibleEdit}
                      onCancel={handleCancelModalEdit}
                    >
                      <UploadAttachment earningsId={filtered[0].id} />
                    </Modal>
                  </Col>
                </Col>
                <Col span={12}>
                  <label>Notes </label>
                  <TextArea
                    rows={3}
                    className="textarea--field"
                    placeholder="Enter the Notes"
                    name="notes"
                    onChange={handleChangeEvent}
                    value={newitems.notes}
                  />
                  {!newitems.notes.trim().length && (
                    <p className="display_error">{errors.notes}</p>
                  )}
                  <br />
                  <br />
                  <label>
                    {editRevenueRecord.textForProjectedDirectExpenses}
                  </label>
                  <Input
                    placeholder="Enter the expenses"
                    name="projected_expenses"
                    value={newitems.projected_expenses}
                    onChange={(event) => {
                      const val = allowOnlyNumbers(event);
                      if (val !== false) {
                        return handleChangeDirectExpenseEvent(event);
                      }
                      return false;
                    }}
                    addonBefore={<span className="select-before">INR</span>}
                    type="number"
                    className="direct-expense-input-revenue"
                  ></Input>
                </Col>
              </Row>
              <Row>
                <Col span={8} />                
                <Col span={8} />
                <Col span={8}>
                  <div className="po-button-container">
                    <ButtonReuse
                      type="primary"
                      className="update-po-button"
                      htmlType="submit"
                      value="Save"
                      onClick={() => {
                        props.setEdit(false);
                        handleEdit();
                      }}
                    ></ButtonReuse>
                    <ButtonReuse
                      value="Cancel"
                      type="primary"
                      className="cancel-button-small"
                      onClick={() => {
                        resetForm();
                        props.setEdit(false);
                        props.handleCancel();
                      }}
                    />
                    <ButtonReuse
                      value="Delete"
                      type="primary"
                      className="delete-button"
                      onClick={() => {
                        props.setDeleteEarnings(filtered[0].id);
                        showModalDelete();
                      }}
                    />
                    <Modal
                      title="Delete Current Month Revenue"
                      visible={isModalVisibleDelete}
                      onCancel={handleCancelDeleteModal}
                      cancelText="Cancel"
                      footer={false}
                      className="deleteModalMain"
                    >
                      <p>{editRevenueRecord.warningTextForDeletingRecord}</p>
                      <Row>
                        <Col span={12}>
                          <ButtonReuse
                            value="Delete"
                            type="submit"
                            className="modal-deleteBtn"
                            onClick={() => {
                              props.deleteData("list");
                              props.handleCancel();
                              resetForm();
                              props.setEdit(false);
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <ButtonReuse
                            value="Cancel"
                            type="submit"
                            className="modal-deleteCancelBtn"
                            onClick={handleCancelDeleteModal}
                          />
                        </Col>
                      </Row>
                    </Modal>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </form>
      )}
    </Formik>
  );

  return <div>{EditRecognitionForm}</div>;
};

export default EditRecordRevenue;
